import request from '../../utils/newRequest';
// 用户登录
export function loginApi(data) {
  return request({
    url: '/app/account/login',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
   
  })
}
// 
export function uploadApi(data) {
  return request({
    url: '/app/draw/upload',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}