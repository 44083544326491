import axios from 'axios'
import qs from 'qs'
let header = {
    // "Content-Type": "application/x-www-form-urlencoded",

}

const request = axios.create({
  // baseURL: 'http://test-mes-app.shtmu.net:7073/',
 
  // baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: 'http://test-mes-app.shtmu.net:7073/', //测试环境
  // baseURL: ' http://scm.theinniverse.com', //正式环境http://draw.theinniverse.com/
  baseURL: 'https://draw.theinniverse.com/', //正式环境http://draw.theinniverse.com/
  // headers: header
})

// 在请求或响应被 then 或 catch 处理前拦截它们。
// 添加请求拦截器  
request.interceptors.request.use((config) => {
  console.log(config)
  // config.headers['TimeStamp'] = getTimeStamp()
  // config.headers['Sign'] = getSign(getTimeStamp())
  config.headers['Mu-Token'] = localStorage.getItem('token')
  if (config.method === 'post') {
    // 具体传参类型, 根据后端接口要求进行设置
    // 参数类型为 formData 格式时, 设置 Content-Type 为 application/x-www-form-urlencoded
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    // config.data = qs.stringify(config.data)
  }
  return config;
}, (error) => {
  // 对响应错误做点什么
  console.log('请求失败')
  return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use((response) => {
  // 对响应数据做点什么
  // console.log(response)
  // if (response.data.status == 'error') {
  //   Toast(response.data.Msg)
  // } else if (response.data.Code != 0 && response.data.Code) {
  //   Toast(response.data.Msg)
  // }

  return response.data;
},
 (error) => {
  console.log('请求404')
  // Toast('请求404')
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default request